export var BaseUrl:string = "https://backoffice.greenpeace.org.ar/api/";
//export var BaseUrl:string = "http://20.195.167.70:3003/";
export var Hashaccess:string = "Prod21-Hashaccess";

export var LoginEndpoint: string = BaseUrl + "users/login";
export var LogoutEndpoint: string = BaseUrl + "users/logout"
export var CreateUserEndpoint: string = BaseUrl + "users/create"
export var EditUserEndpoint: string = BaseUrl + "users/update"
export var ListUserEndpoint: string = BaseUrl + "users/list"
export var FindUserEndpoint: string = BaseUrl + "users/findUserById"
export var UpdateUserEnpoint: string = BaseUrl + "users/update"
export var DeleteUserEnpoint: string = BaseUrl + "users/removeUserByUsername"
export var ShowMyTokenPassEnpoint: string = BaseUrl + 'users/mytoken'
export var ChangePasswordEnpoint: string = BaseUrl + "users/updatePass"
export var DisableUserEndpoint: string = BaseUrl + "users/disable"
export var ValidateAuthEnpoint: string = BaseUrl + "users/verified"
export var LogoutQREndpoint: string = BaseUrl + "users/updateqr"

export var ForgotPassEndpoint: string = BaseUrl + "users/forgot"
export var ResetUserEnpoint: string = BaseUrl + "users/resetUserByUsername"


export var NewTokenEnpoint: string = BaseUrl + "tokens/create"
export var TokenListEndpoint: string = BaseUrl + "tokens/list"
export var TokensTotalEndpoint: string = BaseUrl + "tokens/total"
export var GetTokenByIdEnpoint: string = BaseUrl + "tokens/findTokenById"
export var LogsListEndpoint: string = BaseUrl + "logs/list"
export var LogsTotalEndpoint: string = BaseUrl + "logs/total"

export var UploadFileEndpoint: string = BaseUrl + "files/fileupload"
export var DownloadFileEnpoint: string = BaseUrl + "files/download"

export var DecryptListTokenEnpoint: string = BaseUrl + "tokens/decryptList"

export var CardListEndpoint: string = BaseUrl + "cards/listAll"
export var CardActiveListEndpoint: string = BaseUrl + "cards/list"
export var ChangeStatusCard: string = BaseUrl + "cards/changeStatus"
export var CardCreateEndpoint: string = BaseUrl + "cards/create"
export var CardEditEndpoint: string = BaseUrl + "cards/update"


export var FindByFileEndpoint: string = BaseUrl + "tokens/findByFile"


export var DecryptTokenEnpoint: string = BaseUrl + "tokens/decryptToken"
export var FilterDataEndpoint: string = BaseUrl + "tokens/filterData"
export var CheckMyTokenEnpoint: string = BaseUrl + "tokens/checkToken"

export var DeleteTokenEnpoint: string = BaseUrl + "tokens/removeToken"

export var CloseKeyEnpoint: string = BaseUrl + "tokens/closeKey"


export var UtmsListEndpoint: string = BaseUrl + "forms/listUtms"
export var UtmsTotalEndpoint: string = BaseUrl + "forms/totalUtms"

export var FormsDashboardEnpoint: string = BaseUrl + "forms/dashboard"

export var FormsBaseListEnpoint: string = BaseUrl + "forms/listBase"
export var FormsBaseTotalEnpoint: string = BaseUrl + "forms/totalBase"
export var FormsBaseNewEnpoint: string = BaseUrl + "forms/newBase"
export var FormsBaseEditEnpoint: string = BaseUrl + "forms/editBase"
export var FormsBaseDeleteEnpoint: string = BaseUrl + "forms/deleteBase"
export var FormsDataDownloadEnpoint: string = BaseUrl + "forms/datadownload"

export var AddAttribute: string = BaseUrl + "forms/addAttribute"
export var ListAttribute: string = BaseUrl + "forms/listAttribute"
export var AttributeDeleteEnpoint: string = BaseUrl + "forms/deleteAttribute"



export var FormsListEndpoint: string = BaseUrl + "forms/list"

export var FormsTotalEndpoint: string = BaseUrl + "forms/total" 
export var FromsDetailDataEnpoint: string = BaseUrl + "forms/detail"

export var DownloadFormEnpoint: string = BaseUrl + "forms/download"
