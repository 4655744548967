import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import 'rxjs/add/operator/map'



@Injectable({
  providedIn: 'root'
})
export class NetworkingServices {
  _isLoggedIn: boolean = false

  authSub = new Subject<any>();

  constructor(private _http: HttpClient, private _authService: AuthService) {

  }
  completeHeaders(headerOptions:HttpHeaders){
    headerOptions = headerOptions.append('Auth', this._authService.getJwt());
    headerOptions = headerOptions.append('Iam', sessionStorage.getItem("userAccess"));
    return headerOptions
  }

  get(Endpoint:string,  headerOptions:HttpHeaders){
    var HeaOpt = new HttpHeaders();
    HeaOpt = this.completeHeaders(headerOptions)
    return this._http.get(Endpoint, { observe: 'response' , headers: HeaOpt});    
  }
  
  post(Endpoint:string,params:any, headerOptions:HttpHeaders){
    if (!Endpoint.includes("users/login") && !Endpoint.includes("users/forgot")){
        headerOptions = this.completeHeaders(headerOptions)
    }
    return this._http.post(Endpoint, {params}, { observe: 'response', headers: headerOptions });
  }

  put(Endpoint:string,params:any,headerOptions:HttpHeaders){
    var HeaOpt = new HttpHeaders();
    HeaOpt = this.completeHeaders(headerOptions)
    return this._http.put(Endpoint, {params}, { observe: 'response', headers: HeaOpt });
  }
}
