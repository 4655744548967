import { Component, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

import { NetworkingServices } from './@core/services/networkingServices.service'
import {LogoutEndpoint} from './@core/Endpoints'
import { Subject } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  userActivity;
  userInactive: Subject<any> = new Subject();

  constructor(private _networkingServices: NetworkingServices, private _router:Router) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      console.log('user has been inactive for 300000')
      let dU = sessionStorage.getItem("userAccess")
      let headerOptions = new HttpHeaders();
      this._networkingServices.post(LogoutEndpoint, dU, headerOptions).subscribe((data) =>{
          if(data.status == 200){
            sessionStorage.clear();
            this._router.navigate(['/login'])
          }
      }, (err) => {
          console.log(err)
      })
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 300000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

}
