import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

import { AuthService } from '../@core/services/auth.service';

import { NetworkingServices } from '../@core/services/networkingServices.service'
import { data } from 'jquery';
import {ForgotPassEndpoint} from '../@core/Endpoints'

import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    messaggeError: String = "";
    messageSuccess: String = ""
    forgotForm = new FormGroup({
        username: new FormControl('', [Validators.minLength(4), Validators.maxLength(8)]),
        email: new FormControl('', Validators.email),
        platform: new FormControl('')
    });
  
    constructor(private router: Router, private authService: AuthService, private _networkingServices:NetworkingServices) { }

    ngOnInit() {
    }
    
    onSubmit(){
        console.log("aqui")
        this.messaggeError = ""
        this.messageSuccess = ""
        let params = {
            platform: this.forgotForm.value.platform,
            username: this.forgotForm.value.username,
            email: this.forgotForm.value.email
        }
        let headerOptions = new HttpHeaders();
        console.log(ForgotPassEndpoint)
        this._networkingServices.post(ForgotPassEndpoint, params, headerOptions).subscribe((data) =>{
            this.messageSuccess = "Contraseña temporal enviada."
            this.forgotForm.reset()
        }, (err) => {
            console.log(err)
            this.messaggeError = err.error;
        })
    }

   

}