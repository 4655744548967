import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

import { AuthService } from '../@core/services/auth.service';

import { NetworkingServices } from '../@core/services/networkingServices.service'
import {LoginEndpoint, Hashaccess} from '../@core/Endpoints'
import { data } from 'jquery';

import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    messaggeError: String = "";
    setupToken: Boolean = false;
    authToken: Boolean = false;
    tfa: any = {};
    authcode: string = "";
    nextStep: any;
    profileForm = new FormGroup({
        username: new FormControl(''),
        password: new FormControl('', Validators.minLength(7)),
        platform: new FormControl(''),
        token: new FormControl('', [Validators.maxLength(6), Validators.minLength(6)])
    });
    userLogin:any;
    constructor(private router: Router, private authService: AuthService, private _networkingServices:NetworkingServices) { }

    ngOnInit() {
    }
    
    onSubmit(){
        if(!this.authToken){
            if(this.profileForm.value.password == "" || this.profileForm.value.username == ""){
                this.messaggeError = "Debe completar el formulario."
                return
            }
            if(this.profileForm.value.platform == ""){
                this.messaggeError = "Debe seleccionar una plataforma."
                return
            }

            let ck = CryptoJS.AES.encrypt(this.profileForm.value.password, Hashaccess);

            let params = {
                platform: this.profileForm.value.platform,
                username: this.profileForm.value.username,
                password: ck.toString(),
                secondpass: null
            }
            let headerOptions = new HttpHeaders();
            this._networkingServices.post(LoginEndpoint, params, headerOptions).subscribe((data) =>{
                this.messaggeError = ""
                this.authToken = true;
                this.tfa = data.body;
                this.profileForm.controls['username'].disable();
                this.profileForm.controls['password'].disable();
                if(data.status == 201){
                    this.nextStep = "0"
                    this.setupToken = true;
                }
                if(data.status == 206){
                    this.nextStep = "1"
                    this.setupToken = false
                }
                this.userLogin = {
                    username: params.username,
                    password: params.password
                }
            }, (err) => {
                window.scroll(0,0);
                this.profileForm.controls['username'].enable();
                this.profileForm.controls['password'].enable();
                this.messaggeError = err.error;
            })
        }else{
            if(this.profileForm.value.token == ""){
                window.scroll(0,0);
                this.messaggeError = "Ingrese su Token Auth2."
                return
            }
            this.profileForm.value.password == ""
            var headerOptions;
            if(this.nextStep == "0"){ 
                headerOptions = new HttpHeaders({
                    'x-tfa': this.tfa.tempSecret,
                });
            }
            var Newparams = {
                platform: this.profileForm.value.platform,
                username: this.userLogin.username,
                password: this.userLogin.password,
                secondpass: this.profileForm.value.token,
            }
            this._networkingServices.post(LoginEndpoint, Newparams, headerOptions).subscribe((data) =>{
                this.messaggeError = ""
                sessionStorage.setItem("userAccess", data.body.toString())                    
                if(data.status == 202){
                    this.router.navigate(['/first-time'])
                }  
                if(data.status == 200){    
                    this.router.navigate(['/userzone/dashboard'])
                }   
            }, (err) => {
                window.scroll(0,0);
                this.messaggeError = err.error;
            })
        }
    }

    cancelLogin(){
        this.messaggeError = "";
        this.profileForm.controls['username'].setValue('')
        this.profileForm.controls['password'].setValue('')
        this.profileForm.controls['username'].enable();
        this.profileForm.controls['password'].enable();
        this.authToken = false;
    }

}