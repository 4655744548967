import { CanActivate, Router, ActivatedRouteSnapshot, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './@core/services/auth.service';
import { Role } from './@core/models/role';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }


    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {    
        if (!this.authService.isAuthorized()) {
            this.router.navigate(['login']);
            return false;
        }
        if(route.data.roles){            
            const roles = route.data.roles as Role[];
            let permissions = route.data.roles.filter(x => x === this.authService.getMyRol());
            if(permissions.length == 0){
                this.router.navigate(['login']);
                return false;
            }
        }
        return true;
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isAuthorized()) {
            return false;
        }
        const roles = route.data && route.data.roles as Role[];
        if (roles && !roles.some(r => this.authService.hasRole(r))) {
            return false;
        }

        return true;
    }
}