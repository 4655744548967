import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../@core/services/auth.service';

export interface DialogData {
  title:"",
  content: ""
}


declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTESGREENPOS: RouteInfo[] = [
    { path: '/userzone/logs', title: 'Reportes',  icon:'content_paste', class: '-uADB' },
    { path: '/userzone/token-list', title: 'Tokens',  icon:'table_rows', class: '-uL -uD -uADB' },
    { path: '/userzone/upload', title: 'Carga Masiva de Token',  icon:'cloud_upload', class: '-uL -uADB' },

    { path: '/userzone/presentations', title: 'Presentaciones de Token',  icon:'filter_alt', class: '-uD -uADB' },

    { path: '/userzone/new-token', title: 'Nuevo Token',  icon:'add', class: '-uL -uADB' },
    { path: '/userzone/card-list', title: 'Tipos de tarjetas',  icon:'credit_card', class: '-uL -uADB' },
    
    { path: '/userzone/user-list', title: 'Usuarios',  icon:'group', class: '-uA'},
    { path: '/userzone/myprofile', title: 'Mi Perfil',  icon:'person', class: '' },
    { path: '/userzone/logout', title: 'Salir',  icon:'exit_to_app', class: '' }
];



export const ROUTESFORMA: RouteInfo[] = [
  
  { path: '/userzone/utm-list', title: 'Reportes Utms',  icon:'analytics', class: '-uL' },
  
  { path: '/userzone/forms-list', title: 'Formularios Base',  icon:'line_style', class: '-uADB' },
  
  { path: '/userzone/form-dash', title: 'Forms. Completados',  icon:'grading', class: '-uADB' },

  { path: '/userzone/upload', title: 'Carga de Formularios',  icon:'cloud_upload', class: '-uADB' },

  { path: '/userzone/attributes', title: 'Atributos',  icon:'library_books', class: '-uADB' },


  { path: '/userzone/user-list', title: 'Usuarios',  icon:'group', class: '-uA'},
  { path: '/userzone/myprofile', title: 'Mi Perfil',  icon:'person', class: '' },
  { path: '/userzone/logout', title: 'Salir',  icon:'exit_to_app', class: '' }
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  isAdmin: Boolean;
  platformTitle:string;
  constructor(private authService: AuthService) { }

  ngOnInit() {
    if (this.authService.getMyPlatform() == "1"){
      this.platformTitle = "GREENPOS V1"
      this.menuItems = ROUTESGREENPOS.filter(menuItem => {
      
        let optionClass = menuItem.class.split(" ")
        let permissions = optionClass.filter(x => x === this.authService.getMyRol());
        if(permissions.length > 0 || menuItem.class == ""){
          return true
        }
        return false
      });
    }else{
      this.platformTitle = "FORMA V1"
      this.menuItems = ROUTESFORMA.filter(menuItem => {
        let optionClass = menuItem.class.split(" ")
        let permissions = optionClass.filter(x => x === this.authService.getMyRol());
        if(permissions.length > 0 || menuItem.class == ""){
          return true
        }
        return false
      });
    }

  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };


}