
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Role } from '../models/role';
import * as CryptoJS from 'crypto-js';
import { Hashaccess } from '../Endpoints'

@Injectable()
export class AuthService {
    private user: User;

    isAuthorized() {
        
        return !!sessionStorage.getItem("userAccess");
    }

    hasRole(role: Role) {
      let dU = this.decrypUser(sessionStorage.getItem("userAccess"))
      if (dU == ""){
        return false
      }
      return this.isAuthorized() && dU.role.includes(role);
    }

    getJwt() {
      let dU = this.decrypUser(sessionStorage.getItem("userAccess"))
      if (dU == ""){
        return false
      }
      return dU.jwt
    }

    getMyId() {
      let dU = this.decrypUser(sessionStorage.getItem("userAccess"))
      if (dU == ""){
        return false
      }
      return dU.userId
    }

    logout() {
      return this.decrypUser(sessionStorage.getItem("userAccess"))
    }

    getMyRol(){
      let dU = this.decrypUser(sessionStorage.getItem("userAccess"))
      return dU.role;
    }

    getMyPlatform(){
      let dU = this.decrypUser(sessionStorage.getItem("userAccess"))
      if (dU == ""){
        return false
      }
      return dU.platform;
    }

    decrypUser(tokenUser){
      try {
        const bytes = CryptoJS.AES.decrypt(tokenUser.toString(), Hashaccess);
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
        }
      } catch (e) { 
        return ""
      }
    }
}



