import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {ActivatedRoute} from "@angular/router";
import { HttpHeaders } from '@angular/common/http';
import { NetworkingServices } from '../../@core/services/networkingServices.service'
import {ChangePasswordEnpoint, Hashaccess} from '../../@core/Endpoints'
import { AuthService } from '../../@core/services/auth.service';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';



@Component({
  selector: 'app-first-time',
  templateUrl: './first-time.component.html',
  styleUrls: ['./first-time.component.css']
})

export class FirstTimeComponent implements OnInit {
    messaggeError: String = "";
    passForm = new FormGroup({
        password: new FormControl('', Validators.pattern("^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$")),
        passwordCheck: new FormControl('', Validators.pattern("^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$"))
    });
    
    
    constructor(private _networkingServices: NetworkingServices, private _authService:AuthService, private _router:Router){}

    ngOnInit(){    
    }

    onSubmit(){
        if(this.passForm.value.password != this.passForm.value.passwordCheck){
            this.messaggeError = "Contraseñas deben coincidir."
            return
        }
        let ck = CryptoJS.AES.encrypt(this.passForm.value.password, Hashaccess);
        let params = {
            password: ck.toString()
        }
        let updateUrl = ChangePasswordEnpoint+"/"+this._authService.getMyId()
        this.messaggeError = ""
        let headerOptions = new HttpHeaders();
        this._networkingServices.put(updateUrl, params, headerOptions).subscribe((data) =>{
            this.messaggeError = ""
            this.passForm.controls['passwordCheck'].disable();
            this.passForm.controls['password'].disable();
            this._router.navigate(['/userzone/dashboard'])
        }, (err) => {
            this.passForm.controls['passwordCheck'].enable();
            this.passForm.controls['password'].enable();
            this.messaggeError = "Error en el servidor.";
        })
    }

}
  